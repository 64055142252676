import Vue, { VueConstructor } from 'vue';
import ArticleIndex from './components/ArticleIndex.vue';
import Article from './components/Article.vue';

export interface RouteComponentMap {
  /**
   * A component to list items in this index.
   */
  indexComponent: VueConstructor<Vue>;
  /**
   * An individual item in this index.
   */
  itemComponent: VueConstructor<Vue>;
}

/**
 * Object literal to ease selection of Vue Components for the route generator
 * found at `./src/router/index.ts`.
 *
 * When defining new collections, import the component here, and specify
 * the `indexName` as the directory name in which your collection item resides.
 */
export const componentMap: { [indexName: string]: RouteComponentMap } = {
  // Collection components:
  articles: {
    // An individual post: `src/_content/article/YYYY-MM-DD-title-name.md`
    itemComponent: Article,
    // The index component for listing posts within: `src/_content/article/`
    indexComponent: ArticleIndex,
  },
};
