


























import Vue from 'vue';
import { PropValidator } from 'vue/types/options';
import { ContentLibraryDataObject } from 'vue.config';

export default Vue.extend({
  metaInfo: {
    title: 'Closet Trekkie',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      // Use vmid here for pages that needs to override this value
      {
        vmid: 'description',
        name: 'description',
        content: 'Nothing important.',
      },
    ],
  },
  props: {
    contentLibrary: {
      type: Object,
    } as PropValidator<ContentLibraryDataObject>,
  },
});
