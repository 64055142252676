





import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      currentPath: this.$route.path,
    };
  },
  mounted() {
    // Custom index redirects:
    // When an index path is loaded without a pageNumber,
    // this logic redirects the user to the appropriate
    // index page.
    const articleIndexes = ['articles'].map(index => {
      // A leading slash is required for vue-router to know
      // where to navigate to.
      return '/' + index;
    });

    if (articleIndexes.indexOf(this.currentPath) > -1) {
      this.$router.push(this.currentPath + '/1');
    }
  },
});
