import { ContentLibraryDataObject } from 'vue.config';

export default (
  contentLibrary: ContentLibraryDataObject | undefined,
): ContentLibraryDataObject[] | undefined => {
  let recentArticles: ContentLibraryDataObject[] | undefined;

  const articles:
    | ContentLibraryDataObject
    | undefined = contentLibrary?.contentLibraryChildren?.find(
    index => index.indexName === 'articles',
  );

  if (articles) {
    recentArticles =
      articles.contentLibraryChildren?.sort((a, b) => {
        const getDate = (obj: ContentLibraryDataObject) => {
          return +new Date(
            obj?.fileContent?.attributes?.date || new Date().toString(),
          );
        };

        return getDate(b) - getDate(a);
      }) || [];
  }

  return recentArticles;
};
