

























import Vue from 'vue';
import { PropValidator } from 'vue/types/options';
import { ContentLibraryDataObject } from 'vue.config';

export default Vue.extend({
  name: 'ArticlePreview',
  props: {
    article: {
      type: Object,
    } as PropValidator<ContentLibraryDataObject>,
  },
  methods: {
    getImage(obj: ContentLibraryDataObject) {
      return obj?.fileContent?.attributes.featuredImage.replace('/public', '');
    },
    getLink(obj: ContentLibraryDataObject) {
      return obj?.urlPath;
    },
    getDescription(obj: ContentLibraryDataObject) {
      return obj?.fileContent?.attributes?.description;
    },
  },
});
