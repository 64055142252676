import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { ContentLibraryDataObject } from '../../vue.config';
import { componentMap } from '../route-component-map';
import Home from '../views/Home.vue';
import Default from '../views/Default.vue';
import compact from 'lodash/compact';

Vue.use(VueRouter);

export default (contentLibrary?: ContentLibraryDataObject) => {
  /**
   * Recursively constructs a vue-router configuration from the DirectoryTree
   * object provided.
   * @param file
   */
  const buildLibraryRoutes = function(
    file: ContentLibraryDataObject,
  ): RouteConfig | undefined {
    const indexName = file.indexName;
    const resolvedComponent = componentMap[indexName];

    if (!resolvedComponent) {
      return;
    }

    const config: RouteConfig = {
      // If an extension is defined, remove that from the route path.
      path:
        file.type === 'directory' ? `/${indexName}/:pageNumber` : file.urlPath,
      component:
        file.type === 'file' && resolvedComponent.itemComponent
          ? resolvedComponent.itemComponent
          : resolvedComponent.indexComponent,
      props: {
        fileContent: file.fileContent,
        htmlContent: file.htmlContent,
      },
    };

    config.children = compact(
      file.contentLibraryChildren && file.contentLibraryChildren.length > 0
        ? file.contentLibraryChildren.map(file => buildLibraryRoutes(file))
        : [],
    );

    return config;
  };

  let contentRoutes: RouteConfig[] | undefined;

  if (contentLibrary) {
    contentRoutes = compact(
      contentLibrary?.contentLibraryChildren?.map(file =>
        buildLibraryRoutes(file),
      ),
    );
  }

  let routes: Array<RouteConfig> = [
    {
      path: '/',
      name: 'Home',
      component: Home,
      props: {
        contentLibrary: contentLibrary ? contentLibrary : {},
      },
    },
    {
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: 'about' */ '../views/About.vue'),
    },
    {
      path: '/label',
      name: 'Label',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: 'about' */ '../views/Label.vue'),
    },
  ];

  if (contentRoutes) {
    routes = routes.concat(contentRoutes || []);
  }

  routes.push({
    path: '*',
    component: Default,
  });

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
    routes,
  });

  return router;
};
