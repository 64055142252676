






















import Vue from 'vue';

export default Vue.extend({
  props: {
    routePrefix: {
      default: '',
    },
  },
  computed: {
    currentPage(): number {
      return parseInt(this.$route.params.pageNumber || '0', 10);
    },
    articleCount(): number {
      const articleIndex = (this.$router.options.routes || []).find(route => {
        return route.path === '/articles/:pageNumber';
      });

      return articleIndex?.children?.length || 0;
    },
  },
});
