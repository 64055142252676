
















import Vue from 'vue';
import { FrontMatterResult } from 'front-matter';
import { PropValidator } from 'vue/types/options';
import { ArticleMarkdownFile } from 'vue.config';

export interface BlogArticleMarkdownFile extends ArticleMarkdownFile {
  date: string;
}

export default Vue.extend({
  name: 'BlogPost',
  metaInfo() {
    return {
      title: `${this.fileContent.attributes.title} - Articles - Closet Trekkie`,
    };
  },
  props: {
    fileContent: {
      type: Object,
    } as PropValidator<FrontMatterResult<BlogArticleMarkdownFile>>,
    htmlContent: {
      type: String,
    },
  },
});
