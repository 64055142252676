











import Vue from 'vue';
import { PropValidator } from 'vue/types/options';
import { ContentLibraryDataObject } from 'vue.config';
import getSortedArticles from '@/utilities/get-sorted-articles';
import ArticlePreview from '../components/ArticlePreview.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    ArticlePreview,
  },
  props: {
    contentLibrary: {
      type: Object,
      default: () => undefined,
    } as PropValidator<ContentLibraryDataObject | undefined>,
  },
  computed: {
    articles(): ContentLibraryDataObject[] {
      return getSortedArticles(this.contentLibrary)?.slice(0, 4) || [];
    },
  },
});
