

















import Vue from 'vue';
import ArticlePreview from '../components/ArticlePreview.vue';
import { Route, RouteConfig } from 'vue-router';
import Pager from './Pager.vue';

export default Vue.extend({
  name: 'Article',
  components: {
    ArticlePreview,
    Pager,
  },
  data() {
    return {
      routeIndex: undefined as RouteConfig[] | undefined,
      articlesPerPage: 4,
      currentPage: undefined as undefined | number,
    };
  },
  metaInfo: {
    title: 'Articles - Closet Trekkie',
  },
  watch: {
    $route: function(route) {
      this.processRouteParams(route);
      this.loadArticles();
    },
  },
  methods: {
    processRouteParams(route: Route) {
      const pageNumber = route.params.pageNumber;
      this.currentPage = pageNumber ? parseInt(pageNumber, 10) : undefined;
    },
    loadArticles() {
      const currentRoute = this.$router.options.routes?.find(route => {
        return this.$route.name === route.name;
      });

      const currentPage = this.currentPage || 1;

      // Get a copy of the route children to prevent object
      // manipluation when called `.reverse`
      const routeIndex = (currentRoute?.children || []).slice(0);

      routeIndex.reverse();

      // Load the correct set of articles
      this.routeIndex = routeIndex.slice(
        currentPage * this.articlesPerPage - this.articlesPerPage,
        currentPage * this.articlesPerPage,
      );
    },
  },
  mounted() {
    this.processRouteParams(this.$route);
    this.loadArticles();
  },
});
