export default {
  plugins: [
    '@uvue/core/plugins/asyncData',
    '@uvue/core/plugins/middlewares',
    '@uvue/core/plugins/errorHandler',
  ],
  css: {
    normal: 'extract',
    vue: 'extract',
  },
};
